<template>
  <section class="relative w-full bg-zinc-50 py-16 md:py-24" id="projects">
    <!-- Section Grid Overlay -->
    <div class="absolute inset-0 pointer-events-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMCwwLDAsMC4yKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] opacity-60 z-0" />
    
    <!-- Animated Lines -->
    <div class="absolute inset-0 overflow-hidden z-[1]">
      <div
        v-for="(_, i) in 3"
        :key="i"
        class="absolute h-px w-full bg-gradient-to-r from-transparent via-amber-500/10 to-transparent"
        :style="{ top: `${30 * i + 20}%` }"
        v-motion
        :initial="{ x: '-100%', opacity: 0 }"
        :enter="{
          x: '100%',
          opacity: 0.5,
          transition: {
            duration: 3000,
            delay: i * 500,
            repeat: Infinity,
            ease: 'linear'
          }
        }"
      />
    </div>

    <div class="container mx-auto px-4 relative z-10">
      <div
        v-motion
        :initial="{ opacity: 0, y: 20 }"
        :enter="{ opacity: 1, y: 0 }"
        :transition="{ duration: 600 }"
        class="text-center"
      >
        <h2 class="text-4xl sm:text-5xl md:text-7xl font-black uppercase bg-gradient-to-r from-amber-400 via-amber-500 to-amber-400 bg-clip-text text-transparent tracking-tight max-w-full overflow-hidden px-2">
          {{ translations[lang].projects.title }}
        </h2>
        <p class="mx-auto mt-4 max-w-2xl text-lg text-zinc-600">
          {{ translations[lang].projects.description }}
        </p>
      </div>
      
      <div class="mt-16 flex justify-center">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-6xl w-full">
          <div 
            v-for="(project, index) in projects" 
            :key="index"
            class="project-card"
            tabindex="0"
            :class="[index === 0 ? 'coral-accent' : 'teal-accent']"
          >
            <div class="card-inner">
              <!-- Background Image -->
              <img 
                :src="project.image" 
                :alt="translations[lang].projects.items[project.index].title" 
                class="card-image" 
              />
              
              <!-- Overlays -->
              <div class="overlay-top"></div>
              <div class="overlay-bottom"></div>
              
              <!-- Text Content -->
              <div class="card-content">
                <h3 class="card-title">
                  {{ translations[lang].projects.items[project.index].title }}
                </h3>
                <p class="card-description">
                  {{ translations[lang].projects.items[project.index].details }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { translations } from '@/i18n/translations'
import { computed, ref, onMounted } from 'vue'

interface Props {
  lang: 'en' | 'lv' | 'ru'
}

const props = defineProps<Props>()

// Simplified project data with absolute image paths
const projects = [
  {
    image: '/img/a22_small_metal_2.png',
    index: 0
  },
  {
    image: '/img/spice-lee-cooper-600x390.jpg',
    index: 1
  }
]

// Log for debugging
onMounted(() => {
  console.log('Projects section mounted, projects:', projects)
  console.log('Translations:', translations[props.lang].projects)
})
</script>

<style scoped>
.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  aspect-ratio: 16/9;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-card:hover .card-image {
  transform: scale(1.1);
}

.coral-accent {
  --accent-color: #FF7F50;
}

.teal-accent {
  --accent-color: #56ffe5;
}

.overlay-top,
.overlay-bottom {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s cubic-bezier(0.66, 0.08, 0.19, 0.97);
  z-index: 1;
}

.overlay-top {
  top: 0;
}

.overlay-bottom {
  top: 50%;
  transition-delay: 0.15s;
}

.project-card:hover .overlay-top,
.project-card:hover .overlay-bottom {
  transform: scaleX(1);
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  color: transparent;
  z-index: 2;
  transition: color 0.3s ease;
}

.project-card:hover .card-content {
  color: white;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.card-description {
  font-size: 0.875rem;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s ease 0.1s, opacity 0.5s ease 0.1s;
}

.project-card:hover .card-title,
.project-card:hover .card-description {
  transform: translateY(0);
  opacity: 1;
}
</style> 