<template>
  <nav class="fixed top-0 z-50 w-full bg-zinc-900/80 backdrop-blur-sm">
    <div class="mx-auto flex max-w-7xl items-center justify-between px-4 py-3">
      <!-- Logo -->
      <div class="flex items-center space-x-2">
        <a 
          href="#" 
          class="text-xl font-bold text-white hover:text-amber-400 transition-colors"
          @click.prevent="scrollToHero"
        >
          GOD
        </a>
      </div>

      <!-- Desktop Navigation Links -->
      <div class="hidden space-x-8 md:flex">
        <a
          v-for="(section, index) in sections"
          :key="index"
          :href="`#${section}`"
          class="text-sm font-medium text-zinc-300 transition-colors hover:text-white"
          @click.prevent="scrollToSection(section)"
        >
          {{ translations[lang].navigation[section] }}
        </a>
      </div>

      <!-- Language Selector -->
      <div class="hidden items-center space-x-4 md:flex">
        <button
          v-for="language in ['en', 'lv', 'ru']"
          :key="language"
          class="text-sm font-medium transition-colors"
          :class="language === lang ? 'text-white' : 'text-zinc-400 hover:text-white'"
          @click="$emit('update:lang', language as 'en' | 'lv' | 'ru')"
        >
          {{ language.toUpperCase() }}
        </button>
      </div>

      <!-- Mobile Menu Button -->
      <button
        class="relative z-[60] flex h-10 w-10 items-center justify-center md:hidden"
        @click="toggleMenu"
        aria-label="Toggle Menu"
      >
        <div class="flex w-5 transform flex-col items-end justify-between space-y-1.5">
          <span
            class="h-0.5 w-full origin-right transform rounded-full bg-white transition-all duration-300"
            :class="{ 'translate-y-2 rotate-45': isMenuOpen }"
          ></span>
          <span
            class="h-0.5 w-3/4 rounded-full bg-white transition-all duration-300"
            :class="{ 'opacity-0': isMenuOpen }"
          ></span>
          <span
            class="h-0.5 w-full origin-right transform rounded-full bg-white transition-all duration-300"
            :class="{ '-translate-y-2 -rotate-45': isMenuOpen }"
          ></span>
        </div>
      </button>

      <!-- Mobile Menu Overlay -->
      <div
        class="fixed inset-0 z-[55] md:hidden" 
        :class="isMenuOpen ? 'block' : 'hidden'"
        style="background-color: #18181b !important; opacity: 1 !important;"
      >
        <div class="flex min-h-screen flex-col items-center" style="background-color: #18181b !important;">
          <!-- Top logo -->
          <div class="w-full py-3 px-4 border-b border-zinc-800 flex items-center justify-between">
            <div class="w-10"><!-- Empty div for spacing --></div>
            <a 
              href="#" 
              class="text-2xl font-bold text-white hover:text-amber-400 transition-colors"
              @click.prevent="handleLogoClick"
            >
              GOD
            </a>
            <div class="w-10"><!-- Empty div for spacing --></div>
          </div>
          
          <!-- Main content - centered vertically and horizontally -->
          <div class="flex-1 flex items-center justify-center w-full">
            <div class="flex flex-col items-center">
              <!-- Menu items -->
              <div class="flex flex-col items-center space-y-8 mb-8">
                <a
                  v-for="(section, index) in sections"
                  :key="section"
                  :href="`#${section}`"
                  class="text-2xl font-semibold text-white transition-all duration-300 hover:text-amber-400"
                  style="text-shadow: 0 2px 4px rgba(0,0,0,0.3);"
                  @click="handleMobileNavClick(section)"
                >
                  {{ translations[lang].navigation[section] }}
                </a>
              </div>

              <!-- Mobile Language Selector -->
              <div class="flex items-center space-x-8 border-t border-zinc-700 pt-6">
                <button
                  v-for="language in ['en', 'lv', 'ru']"
                  :key="language"
                  class="text-sm font-medium transition-colors px-3 py-1 rounded"
                  :class="language === lang ? 'bg-amber-500/20 text-amber-400' : 'text-zinc-400 hover:text-white'"
                  @click="handleLanguageChange(language as 'en' | 'lv' | 'ru')"
                >
                  {{ language.toUpperCase() }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { translations } from '@/i18n/translations'

interface Props {
  lang: 'en' | 'lv' | 'ru'
}

const props = defineProps<Props>()
const emit = defineEmits<{
  'update:lang': [value: 'en' | 'lv' | 'ru']
}>()

const sections = ['about', 'products', 'projects', 'partners', 'contact']
const isMenuOpen = ref(false)

// Toggle body scroll when menu is open
watch(isMenuOpen, (value) => {
  if (value) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const handleMobileNavClick = (section: string) => {
  isMenuOpen.value = false
  scrollToSection(section)
}

const handleLanguageChange = (language: 'en' | 'lv' | 'ru') => {
  isMenuOpen.value = false
  emit('update:lang', language)
}

const scrollToHero = () => {
  const element = document.getElementById('hero')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const handleLogoClick = () => {
  isMenuOpen.value = false
  scrollToHero()
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.menu-items-enter-active,
.menu-items-leave-active {
  transition: all 0.3s ease;
}

.menu-items-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.menu-items-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.menu-items-move {
  transition: transform 0.3s ease;
}

/* Stagger menu items animation */
.menu-items-enter-active {
  transition-delay: calc(var(--index) * 50ms);
}
</style> 