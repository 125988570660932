<template>
  <section class="relative w-full overflow-hidden bg-zinc-900 py-6" id="partners">
    <!-- Background Pattern -->
    <div class="absolute inset-0 opacity-20">
      <div
        class="h-full w-full bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4xKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] bg-center pointer-events-none"
      />
    </div>

    <div class="container relative mx-auto px-4">
      <div
        v-motion
        :initial="{ opacity: 0, y: 20 }"
        :enter="{ opacity: 1, y: 0 }"
        :transition="{ duration: 600 }"
        class="text-center"
      >
        <h2
          class="text-4xl sm:text-5xl md:text-7xl font-black uppercase bg-gradient-to-r from-amber-400 via-amber-500 to-amber-400 bg-clip-text text-transparent tracking-tight max-w-full overflow-hidden px-2"
        >
          {{ translations[lang].partners.title }}
        </h2>
      </div>

      <div class="relative mt-12">
        <div class="mx-auto max-h-[60px] max-w-[960px] overflow-hidden">
          <div class="relative">
            <div
              class="pointer-events-none absolute left-0 top-0 z-10 h-full w-[100px] bg-gradient-to-r from-zinc-900 to-transparent"
            />
            <div
              class="pointer-events-none absolute right-0 top-0 z-10 h-full w-[100px] bg-gradient-to-l from-zinc-900 to-transparent"
            />

            <div class="flex w-[calc(200px*14)] animate-[infiniteLoop_30s_linear_infinite]">
              <template v-for="(partner, index) in partners" :key="`${partner}-${index}`">
                <div class="mx-auto flex h-[30px] w-[200px] items-center justify-center">
                  <p
                    v-motion
                    :initial="{ opacity: 0 }"
                    :enter="{ opacity: 1 }"
                    :transition="{ duration: 1200, delay: index * 100 }"
                    class="text-center text-lg font-medium text-zinc-400 transition-all duration-300 hover:text-amber-400"
                  >
                    {{ partner }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { translations } from '@/i18n/translations'

interface Props {
  lang?: 'en' | 'lv' | 'ru'
}

withDefaults(defineProps<Props>(), {
  lang: 'lv'
})

const partners = [
  'DEPO',
  'EVOLUTION GAMING',
  'Iļģuciema Maiznīca',
  'Schoeller Allibert',
  'CosyWOOD',
  'Buvbaze',
  'A22 Hotel',
  // Duplicate partners for seamless loop
  'DEPO',
  'EVOLUTION GAMING',
  'Iļģuciema Maiznīca',
  'Schoeller Allibert',
  'CosyWOOD',
  'Buvbaze',
  'A22 Hotel'
]
</script>

<style>
@keyframes infiniteLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 7));
  }
}
</style> 