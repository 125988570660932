<template>
  <div id="hero" class="relative h-screen min-h-[600px] w-full overflow-hidden bg-zinc-900">
    <!-- Background with Gradient Overlay -->
    <div
      class="absolute inset-0 bg-cover bg-center"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-black/70" />

      <!-- Restored Futuristic Grid Overlay - this is specific to the hero with white lines -->
      <div class="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4xKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] opacity-20" />
    </div>
    
    <!-- Particle Background - ensure it's visible and loaded -->
    <ParticleBackground />

    <!-- Content Container -->
    <div class="relative z-10 flex h-full flex-col items-center justify-center px-4 text-center">
      
      <!-- Looping Words Animation -->
      <div class="looping-words">
        <div class="looping-words__containers">
          <ul ref="wordsList" data-looping-words-list class="looping-words__list">
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[0] }}</p>
            </li>
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[1] }}</p>
            </li>
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[2] }} {{ translations[lang].hero.heading.split(' ')[3] || '' }}</p>
            </li>
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[0] }}</p>
            </li>
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[1] }}</p>
            </li>
            <li class="looping-words__list-item">
              <p class="looping-words__p">{{ translations[lang].hero.heading.split(' ')[2] }} {{ translations[lang].hero.heading.split(' ')[3] || '' }}</p>
            </li>
          </ul>
        </div>
        <div ref="edgeElement" data-looping-words-selector class="looping-words__selector">
          <div class="looping-words__edge"></div>
          <div class="looping-words__edge is--2"></div>
          <div class="looping-words__edge is--3"></div>
          <div class="looping-words__edge is--4"></div>
        </div>
      </div>
      
      <p class="mx-auto max-w-2xl text-lg text-zinc-300 sm:text-xl md:text-2xl mt-16">
        {{ translations[lang].hero.subheading }}
      </p>

      <div class="mt-8">
        <!-- Confetti Button -->
        <confetti-button 
          :text="translations[lang].hero.ctaText" 
          :onClick="scrollToContact"
          class="text-lg px-6 py-3"
        />
      </div>
    </div>

    <!-- Decorative Bottom Gradient -->
    <div class="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />
  </div>
</template>

<script setup lang="ts">
import { translations } from '@/i18n/translations'
import { onMounted, onUnmounted, ref } from 'vue'
import ParticleBackground from './ParticleBackground.vue'
import ConfettiButton from './ConfettiButton.vue'

interface Props {
  lang: 'en' | 'lv' | 'ru'
  backgroundImage?: string
}

const props = withDefaults(defineProps<Props>(), {
  backgroundImage: 'https://images.unsplash.com/photo-1504917595217-d4dc5ebe6122?q=80&w=2070&auto=format&fit=crop'
})

const wordsList = ref<HTMLUListElement | null>(null)
const edgeElement = ref<HTMLDivElement | null>(null)

const scrollToContact = () => {
  const contactSection = document.getElementById('contact')
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: 'smooth' })
  }
}

let timeline: any = null

onMounted(() => {
  if (typeof window === 'undefined' || !window.gsap) {
    console.error('GSAP not loaded')
    return
  }

  const gsap = window.gsap
  
  // Use the same animation logic as the reference
  if (!wordsList.value || !edgeElement.value) return

  const wordsElements = Array.from(wordsList.value.children) as HTMLElement[]
  const totalWords = wordsElements.length
  const wordHeight = 100 / totalWords // Offset as a percentage
  let currentIndex = 0

  function updateEdgeWidth() {
    if (!wordsList.value || !edgeElement.value) return
    
    const centerIndex = (currentIndex + 1) % totalWords
    const centerWord = wordsElements[centerIndex]
    const centerWordWidth = centerWord.getBoundingClientRect().width
    const listWidth = wordsList.value.getBoundingClientRect().width
    const percentageWidth = (centerWordWidth / listWidth) * 100
    
    gsap.to(edgeElement.value, {
      width: `${percentageWidth}%`,
      duration: 0.5,
      ease: 'expo.easeOut',
    })
  }

  function moveWords() {
    if (!wordsList.value) return
    
    currentIndex++
    gsap.to(wordsList.value, {
      yPercent: -wordHeight * currentIndex,
      duration: 1.2,
      ease: 'elastic.out(1, 0.85)',
      onStart: updateEdgeWidth,
      onComplete: function() {
        if (!wordsList.value) return
        
        if (currentIndex >= totalWords - 3) {
          const firstChild = wordsList.value.children[0]
          if (firstChild) {
            wordsList.value.appendChild(firstChild)
            currentIndex--
            gsap.set(wordsList.value, { yPercent: -wordHeight * currentIndex })
            wordsElements.push(wordsElements.shift() as HTMLElement)
          }
        }
      }
    })
  }

  // Initial edge width
  updateEdgeWidth()

  // Create animation timeline
  timeline = gsap.timeline({ repeat: -1, delay: 1 })
    .call(moveWords)
    .to({}, { duration: 2 })
    .repeat(-1)
})

// Clean up
onUnmounted(() => {
  if (timeline) {
    timeline.kill()
  }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

/* Looping Words Animation Styles */
.looping-words {
  height: 2.7em;
  padding-left: .1em;
  padding-right: .1em;
  font-size: 9vw;
  line-height: .9;
  position: relative;
}

.looping-words__list {
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  flex-flow: column;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  list-style: none;
  display: flex;
  position: relative;
}

.looping-words__list-item {
  width: 100%;
  text-align: center;
}

.looping-words__p {
  margin: 0;
  color: #F59E0B;
  text-shadow: 0 0 6px rgba(245, 158, 11, 0.4);
  background: linear-gradient(to bottom, #F59E0B, #FBBF24);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.looping-words__selector {
  pointer-events: none;
  width: 100%;
  height: .9em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.looping-words__edge {
  border-top: .035em solid #F59E0B;
  border-left: .035em solid #F59E0B;
  width: .125em;
  height: .125em;
  position: absolute;
  top: 0;
  left: 0;
}

.looping-words__edge.is--2 {
  left: auto;
  right: 0;
  transform: rotate(90deg);
}

.looping-words__edge.is--3 {
  inset: auto 0 0 auto;
  transform: rotate(180deg);
}

.looping-words__edge.is--4 {
  top: auto;
  bottom: 0;
  transform: rotate(270deg);
}

.looping-words__containers {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
}

@media (max-width: 768px) {
  .looping-words {
    font-size: 9.45vw;
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  to {
    transform: translateX(100%);
    opacity: 0.5;
  }
}

.animate-shine {
  animation: shine 2s infinite linear;
}

/* Let's remove the fade style */
.looping-words__fade {
  display: none;
}
</style> 