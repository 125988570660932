<template>
  <section id="products" class="products-section relative py-6 sm:py-8">
    <!-- Section Grid Overlay -->
    <div class="absolute inset-0 pointer-events-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMCwwLDAsMC4xKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] opacity-40" />
    
    <div class="relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-8">
        <h2 class="text-4xl sm:text-5xl md:text-7xl font-black uppercase text-zinc-900 tracking-tight max-w-full overflow-hidden px-2" style="color: #18181b !important; background: none; -webkit-text-fill-color: #18181b;">
          {{ translations[lang].products.title }}
        </h2>
        <p class="mt-4 text-lg text-zinc-600 max-w-3xl mx-auto">
          {{ translations[lang].products.description }}
        </p>
      </div>

      <!-- Blog Slider / Products Carousel -->
      <div class="blog-slider">
        <div class="blog-slider__wrp swiper-wrapper">
          <!-- First slide with hardcoded image -->
          <div class="blog-slider__item swiper-slide">
            <div class="blog-slider__img">
              <img src="/img/1.jpg" alt="Industrial Style" @error="handleImageError">
            </div>
            <div class="blog-slider__content">
              <div class="blog-slider__title">{{ productItems[0]?.title || "Industrial Style" }}</div>
              <div class="blog-slider__text">{{ productItems[0]?.description || "Serial models and exclusive custom orders" }}</div>
              <a href="#contact" class="blog-slider__button">{{ translations[lang].hero.ctaText }}</a>
            </div>
          </div>
          
          <!-- Remaining slides from productItems, starting from index 1 -->
          <div class="blog-slider__item swiper-slide" v-for="(product, index) in productItems.slice(1)" :key="index+1">
            <div class="blog-slider__img">
              <img :src="product.image" :alt="product.title" @error="handleImageError">
            </div>
            <div class="blog-slider__content">
              <div class="blog-slider__title">{{ product.title }}</div>
              <div class="blog-slider__text">{{ product.description }}</div>
              <a href="#contact" class="blog-slider__button">{{ translations[lang].hero.ctaText }}</a>
            </div>
          </div>
        </div>
        <div class="blog-slider__pagination"></div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import { translations } from '@/i18n/translations'
import Swiper from 'swiper'
import { EffectFade, Mousewheel, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

interface Props {
  lang: 'en' | 'lv' | 'ru'
}

const props = defineProps<Props>()
let swiperInstance = null

// Fix image paths by ensuring they start with the correct public path
const productItems = computed(() => {
  return translations[props.lang].products.items.map(item => {
    // Make sure images are properly referenced
    const fixedImagePath = item.image.startsWith('/')
      ? item.image  // Leave absolute paths as they are
      : `/${item.image}` // Ensure relative paths have a leading slash

    return {
      ...item,
      image: fixedImagePath
    }
  })
})

// Handle missing images by setting a placeholder
const handleImageError = (e: Event) => {
  const imgElement = e.target as HTMLImageElement
  imgElement.src = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="250" height="200" viewBox="0 0 250 200"><rect width="250" height="200" fill="%23220033"/><text x="50%" y="50%" font-family="Arial" font-size="20" text-anchor="middle" fill="white">Image Missing</text></svg>'
}

// Initialize Swiper
onMounted(() => {
  swiperInstance = new Swiper('.blog-slider', {
    modules: [EffectFade, Mousewheel, Pagination, Autoplay],
    spaceBetween: 30,
    effect: 'fade',
    loop: true,
    mousewheel: {
      invert: false,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.blog-slider__pagination',
      clickable: true,
    }
  })
})

// Clean up when component is unmounted
onUnmounted(() => {
  if (swiperInstance) {
    swiperInstance.destroy()
    swiperInstance = null
  }
})
</script>

<style scoped>
.products-section {
  background: #f5f5f5;
  padding: 30px 0;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.blog-slider {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all .3s;
}

@media screen and (max-width: 992px) {
  .blog-slider {
    max-width: 680px;
    height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .blog-slider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}

@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider {
    height: 350px;
  }
}

.blog-slider__item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .blog-slider__item {
    flex-direction: column;
  }
}

.blog-slider__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: .3s;
}

.blog-slider__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  transform: none;
}

.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}

.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}

.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}

.blog-slider__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  background-image: linear-gradient(147deg, #FBBF24 0%, #F59E0B 74%);
  box-shadow: 4px 13px 30px 1px rgba(251, 191, 36, 0.3);
  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
}

.blog-slider__img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(147deg, #FBBF24 0%, #F59E0B 74%);
  border-radius: 20px;
  opacity: 0;
}

.blog-slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all .3s;
}

@media screen and (max-width: 992px) {
  .blog-slider__img {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .blog-slider__img {
    transform: translateY(-50%);
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .blog-slider__img {
    width: 95%;
  }
}

@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider__img {
    height: 270px;
  }
}

.blog-slider__content {
  padding-right: 25px;
}

@media screen and (max-width: 992px) {
  .blog-slider__content {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .blog-slider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}

@media screen and (max-width: 576px) {
  .blog-slider__content {
    padding: 0;
  }
}

.blog-slider__content > * {
  opacity: 0;
  transform: translateY(25px);
  transition: all .4s;
}

.blog-slider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}

.blog-slider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}

.blog-slider__button {
  display: inline-flex;
  background-image: linear-gradient(147deg, #FBBF24 0%, #F59E0B 74%);
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px rgba(251, 191, 36, 0.4);
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}

@media screen and (max-width: 576px) {
  .blog-slider__button {
    width: 100%;
  }
}

.blog-slider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .blog-slider__pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}

@media screen and (max-width: 768px) {
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}

.blog-slider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all .3s;
}

.blog-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #F59E0B;
  height: 30px;
  box-shadow: 0px 0px 20px rgba(251, 191, 36, 0.3);
}

@media screen and (max-width: 768px) {
  .blog-slider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}
</style> 