export const translations = {
  en: {
    loading: "Loading...",
    navigation: {
      about: "About",
      products: "Products",
      projects: "Projects",
      partners: "Partners",
      contact: "Contact"
    },
    hero: {
      heading: "QUALITY PROVEN THROUGH YEARS",
      subheading:
        "Metal constructions and unique products for your home or bathroom",
      ctaText: "Learn More",
    },
    about: {
      title: "About Us",
      description:
        "GOD was founded in 1998 and started its operations with the manufacturing of towel dryers. At present, we have expanded our operations and recommend ourselves as a reliable partner – not only in the local market but also beyond Latvia's borders.",
      timeline: [
        {
          year: "1998",
          title: "Company Foundation",
          description: "Started with towel dryer production",
        },
        {
          year: "2005",
          title: "Product Expansion",
          description: "Began manufacturing pool ladders and railings",
        },
        {
          year: "2015",
          title: "International Growth",
          description: "Started exporting abroad",
        },
        {
          year: "2024",
          title: "Individual Approach",
          description:
            "Continuing to grow with individual approach to each client",
        },
      ],
    },
    products: {
      title: "We Manufacture",
      description:
        "Our innovative products combine advanced technology with sustainable metal product design.",
      items: [
        {
          title: "Industrial Style",
          description: "Serial models and exclusive custom orders",
          image: "/img/products/industrial.jpg",
        },
        {
          title: "Towel Dryers",
          description: "Quality and modern towel dryers for your bathroom",
          image: "/img/products/towel_dryer.jpg",
        },
        {
          title: "Pool Ladders",
          description: "Safe and stylish pool ladders made of stainless steel",
          image: "/img/products/pool_ladder.jpg",
        },
        {
          title: "Boat Ladders",
          description:
            "Quality and modern boat ladders made of stainless steel",
          image: "/img/products/boat_ladder.jpg",
        },
        {
          title: "Railings",
          description: "Customizable railings for stairs and balconies",
          image: "/img/products/railing.jpg",
        },
        {
          title: "Bicycle Stands",
          description: "Durable and functional bicycle parking stands",
          image: "/img/products/bicycle_stand.jpg",
        },
      ],
    },
    projects: {
      title: "Some of Our Impressive Projects",
      description:
        "Get acquainted with our most significant projects that demonstrate our skills and experience in metal construction.",
      items: [
        {
          title: "A22 Apartments, Riga",
          description: "From metal to works of art",
          details:
            "Exclusive metal elements and design solutions in a modern residential complex",
        },
        {
          title: "Lee Cooper, Spice, Riga",
          description: "Metal clothing rack",
          details: "Specially designed metal structures for store interior",
        },
      ],
    },
    partners: {
      title: "Our Partners",
    },
    contact: {
      title: "Contact Us",
      address: {
        title: "Address",
        text: "5 Tvaikona Street, Kurzeme District, Riga, LV-1007",
      },
      hours: {
        title: "Working Hours",
        weekdays: "Mon-Fri 09:00 - 18:00",
        saturday: "Sat 09:00 - 15:00",
        sunday: "Sun Closed",
      },
      email: {
        title: "Email",
      },
      phone: {
        title: "Phones",
      },
      form: {
        name: "Name",
        email: "Email",
        message: "Message",
        submit: "Send Message"
      }
    },
    footer: {
      slogan: "QUALITY PROVEN THROUGH YEARS",
      rights: "All rights reserved.",
      quickLinks: "Quick Links"
    },
    badge: {
      text: "Made in Latvia",
    },
  },
  lv: {
    loading: "Ielādē...",
    navigation: {
      about: "Par Mums",
      products: "Produkti",
      projects: "Projekti",
      partners: "Partneri",
      contact: "Kontakti"
    },
    hero: {
      heading: "KVALITĀTES PĀRBAUDĪTA AR GADIEM",
      subheading:
        "Metāla konstrukcijas un unikāli izstrādājumi jūsu mājai vai vannas istabai",
      ctaText: "Uzziniet vairāk",
    },
    about: {
      title: "Par Mums",
      description:
        "Uzņēmums GOD tika dibināts 1998. gadā, un uzsāka savu darbību ar dvieļu žāvētāju izgatavošanu. Uz šo brīdi mēs esam paplašinājuši savu darbību un rekomendējam sevi kā uzticamu partneri – ne tikai vietējā tirgū, bet arī aiz Latvijas robežām.",
      timeline: [
        {
          year: "1998",
          title: "Uzņēmuma dibināšana",
          description: "Sākām ar dvieļu žāvētāju ražošanu",
        },
        {
          year: "2005",
          title: "Produktu paplašināšana",
          description: "Uzsākām baseinu kāpņu un margu ražošanu",
        },
        {
          year: "2015",
          title: "Starptautiskā izaugsme",
          description: "Uzsākām eksportu arvalstiem",
        },
        {
          year: "2024",
          title: "Individuāla pieeja",
          description: "Turpinām augt ar individuālu pieeju katram klientam",
        },
      ],
    },
    products: {
      title: "Mēs Izgatavojam",
      description:
        "Mūsu inovatīvie produkti apvieno progresīvās tehnoloģijas ar ilgtspējīgu metāla izstrādājumu dizainu.",
      items: [
        {
          title: "Industriālais stils",
          description: "Sērijveida modeļi un ekskluzīvi pēc pasūtījuma",
          image: "/img/products/industrial.jpg",
        },
        {
          title: "Dvieļu Žāvētāji",
          description:
            "Kvalitatīvi un moderni dvieļu žāvētāji jūsu vannas istabai",
          image: "/img/products/towel_dryer.jpg",
        },
        {
          title: "Baseinu Kāpnes",
          description:
            "Drošas un stilīgas baseinu kāpnes no nerūsējošā tērauda",
          image: "/img/products/pool_ladder.jpg",
        },
        {
          title: "Bortu Kāpnes",
          description:
            "Kvalitatīvas un modernas bortu kāpnes no nerūsējošā tērauda",
          image: "/img/products/boat_ladder.jpg",
        },
        {
          title: "Margas",
          description: "Pielāgojamas margas kāpnēm un balkoniem",
          image: "/img/products/railing.jpg",
        },
        {
          title: "Velosipēdu Statīvi",
          description:
            "Izturīgi un funkcionāli velosipēdu novietošanas statīvi",
          image: "/img/products/bicycle_stand.jpg",
        },
      ],
    },
    projects: {
      title: "Pāris no iespaidīgajiem projektiem",
      description:
        "Iepazīstieties ar mūsu nozīmīgākajiem projektiem, kas demonstrē mūsu prasmes un pieredzi metāla konstrukciju izstrādē.",
      items: [
        {
          title: "A22 Apartments, Rīga",
          description: "No metāla līdz mākslas darbiem",
          details:
            "Ekskluzīvi metāla elementi un dizaina risinājumi modernā dzīvojamā kompleksā",
        },
        {
          title: "Lee Cooper, Spice, Rīga",
          description: "Metāla drēbju pakaramais",
          details:
            "Īpaši izstrādātas metāla konstrukcijas veikala iekārtojumam",
        },
      ],
    },
    partners: {
      title: "Mūsu Partneri",
    },
    contact: {
      title: "Sazinies Ar Mums",
      address: {
        title: "Adrese",
        text: "Tvaikoņu iela 5, Kurzemes rajons, Rīga, LV-1007",
      },
      hours: {
        title: "Darba laiks",
        weekdays: "P.-Pk. 09:00 - 18:00",
        saturday: "S. 09:00 - 15:00",
        sunday: "Sv. Slēgts",
      },
      email: {
        title: "E-pasts",
      },
      phone: {
        title: "Tālruņi",
      },
      form: {
        name: "Vārds",
        email: "E-pasts",
        message: "Ziņojums",
        submit: "Nosūtīt Ziņojumu"
      }
    },
    footer: {
      slogan: "KVALITĀTES PĀRBAUDĪTA AR GADIEM",
      rights: "Visas tiesības aizsargātas.",
      quickLinks: "Ātrās Saites"
    },
    badge: {
      text: "Ražots Latvijā",
    },
  },
  ru: {
    loading: "Загрузка...",
    navigation: {
      about: "О Нас",
      products: "Продукты",
      projects: "Проекты",
      partners: "Партнеры",
      contact: "Контакты"
    },
    hero: {
      heading: "КАЧЕСТВО ПРОВЕРЕННОЕ ГОДАМИ",
      subheading:
        "Металлоконструкции и уникальные изделия для вашего дома или ванной комнаты",
      ctaText: "Узнать больше",
    },
    about: {
      title: "О Нас",
      description:
        "Компания GOD была основана в 1998 году и начала свою деятельность с производства полотенцесушителей. На данный момент мы расширили свою деятельность и рекомендуем себя как надежного партнера – не только на местном рынке, но и за пределами Латвии.",
      timeline: [
        {
          year: "1998",
          title: "Основание компании",
          description: "Начали с производства полотенцесушителей",
        },
        {
          year: "2005",
          title: "Расширение продукции",
          description: "Начали производство лестниц для бассейнов и перил",
        },
        {
          year: "2015",
          title: "Международный рост",
          description: "Начали экспорт за рубеж",
        },
        {
          year: "2024",
          title: "Индивидуальный подход",
          description:
            "Продолжаем расти с индивидуальным подходом к каждому клиенту",
        },
      ],
    },
    products: {
      title: "Мы Производим",
      description:
        "Наши инновационные продукты сочетают передовые технологии с устойчивым дизайном металлических изделий.",
      items: [
        {
          title: "Индустриальный стиль",
          description: "Серийные модели и эксклюзивные заказы",
          image: "/img/products/industrial.jpg",
        },
        {
          title: "Полотенцесушители",
          description:
            "Качественные и современные полотенцесушители для вашей ванной",
          image: "/img/products/towel_dryer.jpg",
        },
        {
          title: "Лестницы для бассейнов",
          description:
            "Безопасные и стильные лестницы для бассейнов из нержавеющей стали",
          image: "/img/products/pool_ladder.jpg",
        },
        {
          title: "Лестницы для бортов",
          description:
            "Качественные и современные лестницы для бортов из нержавеющей стали",
          image: "/img/products/boat_ladder.jpg",
        },
        {
          title: "Перила",
          description: "Настраиваемые перила для лестниц и балконов",
          image: "/img/products/railing.jpg",
        },
        {
          title: "Велосипедные стойки",
          description: "Прочные и функциональные стойки для парковки велосипедов",
          image: "/img/products/bicycle_stand.jpg",
        },
      ],
    },
    projects: {
      title: "Некоторые из наших впечатляющих проектов",
      description:
        "Познакомьтесь с нашими самыми значимыми проектами, которые демонстрируют наши навыки и опыт в металлоконструкциях.",
      items: [
        {
          title: "A22 Apartments, Рига",
          description: "От металла до произведений искусства",
          details:
            "Эксклюзивные металлические элементы и дизайнерские решения в современном жилом комплексе",
        },
        {
          title: "Lee Cooper, Spice, Рига",
          description: "Металлическая вешалка для одежды",
          details:
            "Специально разработанные металлоконструкции для интерьера магазина",
        },
      ],
    },
    partners: {
      title: "Наши Партнеры",
    },
    contact: {
      title: "Свяжитесь с нами",
      address: {
        title: "Адрес",
        text: "ул. Твайконю 5, Курземский район, Рига, LV-1007",
      },
      hours: {
        title: "Время работы",
        weekdays: "Пн-Пт 09:00 - 18:00",
        saturday: "Сб 09:00 - 15:00",
        sunday: "Вс Закрыто",
      },
      email: {
        title: "Эл. почта",
      },
      phone: {
        title: "Телефоны",
      },
      form: {
        name: "Имя",
        email: "Эл. почта",
        message: "Сообщение",
        submit: "Отправить Сообщение"
      }
    },
    footer: {
      slogan: "КАЧЕСТВО ПРОВЕРЕННОЕ ГОДАМИ",
      rights: "Все права защищены.",
      quickLinks: "Быстрые Ссылки"
    },
    badge: {
      text: "Сделано в Латвии",
    },
  },
} as const; 