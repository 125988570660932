<template>
  <footer class="relative bg-zinc-900 py-12">
    <!-- Footer Grid Overlay -->
    <div class="absolute inset-0 pointer-events-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4xKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] opacity-20" />
    
    <div class="relative z-10 mx-auto max-w-7xl px-4">
      <div class="grid gap-8 md:grid-cols-3">
        <!-- Company Info -->
        <div>
          <h3 class="text-xl font-bold text-white">GOD</h3>
          <p class="mt-4 text-zinc-400">
            {{ translations[lang].footer.slogan }}
          </p>
        </div>

        <!-- Quick Links -->
        <div>
          <h3 class="text-lg font-semibold text-white">
            {{ translations[lang].footer.quickLinks }}
          </h3>
          <ul class="mt-4 space-y-2">
            <li>
              <a
                href="#about"
                class="text-zinc-400 hover:text-white"
                @click.prevent="scrollToSection('about')"
              >
                {{ translations[lang].navigation.about }}
              </a>
            </li>
            <li>
              <a
                href="#products"
                class="text-zinc-400 hover:text-white"
                @click.prevent="scrollToSection('products')"
              >
                {{ translations[lang].navigation.products }}
              </a>
            </li>
            <li>
              <a
                href="#projects"
                class="text-zinc-400 hover:text-white"
                @click.prevent="scrollToSection('projects')"
              >
                {{ translations[lang].navigation.projects }}
              </a>
            </li>
            <li>
              <a
                href="#contact"
                class="text-zinc-400 hover:text-white"
                @click.prevent="scrollToSection('contact')"
              >
                {{ translations[lang].navigation.contact }}
              </a>
            </li>
          </ul>
        </div>

        <!-- Contact Info -->
        <div>
          <h3 class="text-lg font-semibold text-white">
            {{ translations[lang].contact.title }}
          </h3>
          <ul class="mt-4 space-y-2 text-zinc-400">
            <li>Tvaikoņu iela 5, Rīga, LV-1007</li>
            <li>
              <a href="tel:+37129277650" class="hover:text-white">
                +371 292 776 50
              </a>
            </li>
            <li>
              <a href="tel:+37126730252" class="hover:text-white">
                +371 267 302 52
              </a>
            </li>
            <li>
              <a href="mailto:godriga@gmail.com" class="hover:text-white">
                godriga@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Copyright -->
      <div class="mt-12 border-t border-zinc-800 pt-8 text-center text-zinc-400">
        <p>© {{ new Date().getFullYear() }} GOD. {{ translations[lang].footer.rights }}</p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { translations } from '@/i18n/translations'

interface Props {
  lang: 'en' | 'lv' | 'ru'
}

defineProps<Props>()

const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
</script> 