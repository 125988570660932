<template>
  <!-- Add higher z-index and try to ensure it's positioned properly -->
  <div class="absolute inset-0 z-[15]" style="pointer-events: none;">
    <canvas ref="canvas" class="w-full h-full block"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const canvas = ref(null);
let animationId = null;
let stars = [];
const numStars = 1900;
let centerX, centerY;
let focalLength;

// Initialize stars
function initializeStars() {
  if (!canvas.value) return;
  
  centerX = canvas.value.width / 2;
  centerY = canvas.value.height / 2;
  focalLength = canvas.value.width * 2;
  
  stars = [];
  for (let i = 0; i < numStars; i++) {
    const star = {
      x: Math.random() * canvas.value.width,
      y: Math.random() * canvas.value.height,
      z: Math.random() * canvas.value.width,
      o: '0.' + Math.floor(Math.random() * 99) + 1
    };
    stars.push(star);
  }
}

// Move stars
function moveStars() {
  for (let i = 0; i < stars.length; i++) {
    const star = stars[i];
    star.z--;
    
    if (star.z <= 0) {
      star.z = canvas.value.width;
    }
  }
}

// Draw stars
function drawStars() {
  if (!canvas.value) return;
  const ctx = canvas.value.getContext('2d');
  
  // Resize to the screen
  if (canvas.value.width != canvas.value.offsetWidth || canvas.value.height != canvas.value.offsetHeight) {
    canvas.value.width = canvas.value.offsetWidth;
    canvas.value.height = canvas.value.offsetHeight;
    initializeStars();
  }
  
  // Clear canvas with transparent background
  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
  
  // Draw each star
  for (let i = 0; i < stars.length; i++) {
    const star = stars[i];
    
    // Calculate 2D position from 3D coordinates
    const pixelX = (star.x - centerX) * (focalLength / star.z);
    const x2d = pixelX + centerX;
    const pixelY = (star.y - centerY) * (focalLength / star.z);
    const y2d = pixelY + centerY;
    const pixelRadius = 0.8 * (focalLength / star.z); // Reduced size by 60%
    
    // Set gold color with varying opacity
    ctx.fillStyle = `rgba(255, 215, 0, ${star.o})`;
    ctx.fillRect(x2d, y2d, pixelRadius, pixelRadius);
    
    // Add glow effect for larger particles
    if (pixelRadius > 1.8) { // Adjusted threshold for smaller particles
      ctx.beginPath();
      ctx.arc(x2d + pixelRadius/2, y2d + pixelRadius/2, pixelRadius * 1.5, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(255, 165, 0, ${star.o * 0.3})`;
      ctx.fill();
    }
  }
}

// Animation frame
function executeFrame() {
  moveStars();
  drawStars();
  animationId = requestAnimationFrame(executeFrame);
}

onMounted(() => {
  if (!canvas.value) {
    console.error('Canvas element not found');
    return;
  }
  
  console.log('Star field animation mounted');
  
  // Set canvas size
  canvas.value.width = canvas.value.offsetWidth;
  canvas.value.height = canvas.value.offsetHeight;
  
  // Initialize the star field
  initializeStars();
  
  // Start animation
  console.log('Starting star field animation');
  executeFrame();
});

onUnmounted(() => {
  // Clean up animation on component unmount
  if (animationId) {
    cancelAnimationFrame(animationId);
    console.log('Star field animation cleaned up');
  }
});
</script>

<style scoped>
canvas {
  /* making sure the canvas is visible for debugging */
  background-color: transparent;
}
</style> 