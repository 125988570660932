<template>
  <div class="relative min-h-screen w-full bg-zinc-50" :key="currentLanguage">
    <!-- Content -->
    <div class="relative z-10">
      <nav-bar :lang="currentLanguage" @update:lang="updateLanguage" />
      <hero-section :lang="currentLanguage" />
      <about-section :lang="currentLanguage" />
      <products-section :lang="currentLanguage" />
      <projects-section :lang="currentLanguage" />
      <partners-section :lang="currentLanguage" />
      <contact-section :lang="currentLanguage" />
      <app-footer :lang="currentLanguage" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import NavBar from '@/components/navigation/NavBar.vue'
import HeroSection from '@/components/hero/HeroSection.vue'
import AboutSection from '@/components/about/AboutSection.vue'
import ProductsSection from '@/components/products/ProductsSection.vue'
import ProjectsSection from '@/components/projects/ProjectsSection.vue'
import PartnersSection from '@/components/partners/PartnersSection.vue'
import ContactSection from '@/components/contact/ContactSection.vue'
import AppFooter from '@/components/footer/AppFooter.vue'

type Language = 'en' | 'lv' | 'ru'

const currentLanguage = ref<Language>('lv')

onMounted(() => {
  const savedLang = localStorage.getItem('preferred-language')
  if (savedLang && ['en', 'lv', 'ru'].includes(savedLang)) {
    currentLanguage.value = savedLang as Language
  }

  // Import GSAP dynamically
  import('gsap').then(module => {
    window.gsap = module.default
  })
})

const updateLanguage = (newLang: Language) => {
  currentLanguage.value = newLang
  localStorage.setItem('preferred-language', newLang)
}
</script>

<style>
@import './assets/main.css';
</style> 