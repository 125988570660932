<template>
  <button 
    ref="buttonRef"
    class="button" 
    :class="buttonType" 
    @click="handleClick"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
  >
    <div class="icon">
      <div class="cannon"></div>
      <div class="confetti">
        <svg viewBox="0 0 18 16">
          <polyline points="1 10 4 7 4 5 6 1" />
          <path d="M4,13 C5.33333333,9 7,7 9,7 C11,7 12.3340042,6 13.0020125,4" />
          <path d="M6,15 C7.83362334,13.6666667 9.83362334,12.6666667 12,12 C14.1663767,11.3333333 15.8330433,9.66666667 17,7" />
        </svg>
        <i></i><i></i><i></i><i></i><i></i><i></i>
        <div ref="emitterRef" class="emitter"></div>
      </div>
    </div>
    <span>{{ text }}</span>
  </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: 'Learn More'
  },
  buttonType: {
    type: String,
    default: ''
  },
  onClick: {
    type: Function,
    default: () => {}
  }
})

const buttonRef = ref(null)
const emitterRef = ref(null)
let gsapInstance = null

onMounted(() => {
  // Import GSAP dynamically 
  import('gsap').then(gsapModule => {
    gsapInstance = gsapModule.default
  })
})

const handleClick = (e) => {
  if (!gsapInstance) return
  
  buttonRef.value.classList.add('success')
  
  gsapInstance.to(buttonRef.value, {
    '--icon-x': -3,
    '--icon-y': 3,
    '--z-before': 0,
    duration: 0.2,
    onComplete() {
      // Create particles with confetti - using angle range to shoot mainly upward/outward
      particles(null, 100, 0, 0, -160, -20)
      
      // Return button to normal state
      gsapInstance.to(buttonRef.value, {
        '--icon-x': 0,
        '--icon-y': 0,
        '--z-before': -6,
        duration: 1,
        ease: 'elastic.out(1, .5)',
        onComplete() {
          buttonRef.value.classList.remove('success')
          props.onClick(e)
        }
      })
    }
  })
}

const handleMouseMove = (e) => {
  if (!buttonRef.value) return
  
  const bounding = buttonRef.value.getBoundingClientRect()
  
  let dy = (e.clientY - bounding.top - bounding.height / 2) / -1
  let dx = (e.clientX - bounding.left - bounding.width / 2) / 10
  
  dy = dy > 10 ? 10 : (dy < -10 ? -10 : dy)
  dx = dx > 4 ? 4 : (dx < -4 ? -4 : dx)
  
  buttonRef.value.style.setProperty('--rx', dy)
  buttonRef.value.style.setProperty('--ry', dx)
}

const handleMouseLeave = () => {
  if (!buttonRef.value) return
  
  buttonRef.value.style.setProperty('--rx', 0)
  buttonRef.value.style.setProperty('--ry', 0)
}

const particles = (parent, quantity, x, y, minAngle, maxAngle) => {
  if (!gsapInstance) return
  
  // Create a container for all confetti particles if it doesn't exist
  let container = document.getElementById('confetti-container')
  if (!container) {
    container = document.createElement('div')
    container.id = 'confetti-container'
    container.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;pointer-events:none;z-index:9999;'
    document.body.appendChild(container)
  }
  
  // Clear existing particles
  container.innerHTML = ''
  
  // Get button position 
  const buttonRect = buttonRef.value.getBoundingClientRect()
  const buttonX = buttonRect.left + buttonRect.width / 2
  const buttonY = buttonRect.top + buttonRect.height / 2
  
  // Colors from the original example
  const colors = [
    '#FFFF04', // Yellow
    '#EA4C89', // Pink
    '#F59E0B', // Orange
    '#4AF2FD'  // Blue
  ]
  
  // Create particles
  for (let i = 0; i < quantity; i++) {
    const particle = document.createElement('div')
    const size = Math.floor(Math.random() * 6) + 5  // 5-10px
    
    // Apply styles directly
    particle.style.cssText = `
      position: absolute;
      width: ${size}px;
      height: ${size}px;
      left: ${buttonX}px;
      top: ${buttonY}px;
      background-color: ${colors[Math.floor(Math.random() * colors.length)]};
      border-radius: 50%;
      will-change: transform;
      box-shadow: 0 0 5px rgba(0,0,0,0.3);
    `
    
    container.appendChild(particle)
    
    // Random angle, based on parameters or full 360 if not specified
    const angle = minAngle && maxAngle 
      ? minAngle + Math.random() * (maxAngle - minAngle) 
      : Math.random() * 360
    
    const distance = 30 + Math.random() * 100
    
    gsapInstance.to(particle, {
      x: Math.cos(angle * Math.PI/180) * distance,
      y: Math.sin(angle * Math.PI/180) * distance + (Math.random() * 40),
      opacity: 0,
      duration: 1 + Math.random(),
      ease: 'power2.out',
      onComplete: () => {
        if (particle.parentNode) {
          particle.parentNode.removeChild(particle)
        }
      }
    })
  }
}

onUnmounted(() => {
  // Cleanup if needed
})
</script>

<style scoped>
.button {
  --background: #F59E0B; /* amber-500 */
  --color: #fff;
  --shadow: rgba(0, 9, 61, 0.24);
  --cannon-dark: #F59E0B; /* amber-500 */
  --cannon-light: #FBBF24; /* amber-400 */
  --cannon-shadow: rgba(13, 15, 24, 0.9);
  --confetti-1: #892AB8;
  --confetti-2: #F59E0B; /* amber-500 */
  --confetti-3: #FFFF04;
  --confetti-4: #4AF2FD;
  --z-before: -6;
  display: table;
  outline: none;
  cursor: pointer;
  position: relative;
  border: 0;
  background: none;
  padding: 9px 22px 9px 16px;
  line-height: 26px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: var(--color);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform var(--transform-duration, 0.4s);
  will-change: transform;
  transform-style: preserve-3d;
  transform: perspective(440px) rotateX(calc(var(--rx, 0) * 1deg)) rotateY(calc(var(--ry, 0) * 1deg)) translateZ(0);
  overflow: visible;
}

.button:hover {
  --transform-duration: 0.16s;
  transform: perspective(440px) rotateX(calc(var(--rx, 0) * 1deg)) rotateY(calc(var(--ry, 0) * 1deg)) translateZ(0);
}

.button:active {
  transform: perspective(440px) rotateX(calc(var(--rx, 0) * 1deg)) rotateY(calc(var(--ry, 0) * 1deg)) translateZ(-10px);
}

.button.success {
  --confetti-scale: 0;
  --stroke-dashoffset: 15;
}

.button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  transform: translateZ(calc(var(--z-before) * 1px));
  background: var(--background);
  box-shadow: 0 4px 8px var(--shadow);
}

.button .icon,
.button span {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
}

.button .icon {
  --z: 2px;
  width: 24px;
  height: 14px;
  margin: 8px 16px 0 0;
  transform: translate(calc(var(--icon-x, 0) * 1px), calc(var(--icon-y, 0) * 1px)) translateZ(2px);
}

.button .icon .confetti {
  position: absolute;
  left: 17px;
  bottom: 9px;
  overflow: visible;
  pointer-events: none;
}

.button .icon .confetti svg {
  width: 18px;
  height: 16px;
  display: block;
  stroke-width: 1px;
  fill: none;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.button .icon .confetti svg * {
  transition: stroke-dashoffset 0.2s;
  stroke-dasharray: 15 20;
  stroke-dashoffset: var(--stroke-dashoffset, 0);
  stroke: var(--stroke-all, var(--stroke, var(--confetti-2)));
}

.button .icon .confetti svg *:nth-child(2) {
  --stroke: var(--confetti-3);
}

.button .icon .confetti svg *:nth-child(3) {
  --stroke: var(--confetti-1);
}

.button .icon .confetti .emitter {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  pointer-events: none;
  z-index: 9999;
  overflow: visible;
}

.button .icon .confetti .emitter div {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  border-radius: 50%;
  position: fixed;
  left: 0;
  top: 0;
  transform-style: preserve-3d;
  background: var(--confetti-all, var(--b, none));
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  will-change: transform;
  z-index: 9999;
}

.button .icon .confetti i {
  width: 4px;
  height: 4px;
  display: block;
  transform: scale(var(--confetti-scale, 0.5));
  position: absolute;
  transition: transform 0.25s;
  left: var(--left, -1px);
  top: var(--top, 3px);
  border-radius: var(--border-radius, 1px);
  background: var(--confetti-background, var(--confetti-3));
}

.button .icon .confetti i:nth-child(2) {
  --left: 9px;
  --top: -1px;
  --border-radius: 2px;
  --confetti-background: var(--confetti-4);
}

.button .icon .confetti i:nth-child(3) {
  --left: 5px;
  --top: 3px;
  --confetti-background: var(--confetti-1);
}

.button .icon .confetti i:nth-child(4) {
  --left: 10px;
  --top: 14px;
  --confetti-background: var(--confetti-2);
}

.button .icon .confetti i:nth-child(5) {
  --left: 9px;
  --top: 7px;
  --confetti-background: var(--confetti-4);
}

.button .icon .confetti i:nth-child(6) {
  --left: 6px;
  --top: 8px;
  --border-radius: 2px;
  --confetti-background: var(--confetti-2);
}

.button .icon .cannon {
  position: relative;
  width: 24px;
  height: 14px;
  transform: translate(0, 3px) rotate(-45deg);
  filter: drop-shadow(-2px 2px 2px var(--cannon-shadow));
}

.button .icon .cannon:before,
.button .icon .cannon:after {
  content: '';
  display: block;
  height: 14px;
}

.button .icon .cannon:before {
  background: linear-gradient(var(--cannon-dark), var(--cannon-light) 50%, var(--cannon-dark));
  width: 100%;
  -webkit-clip-path: polygon(25px -1px, 0 52%, 25px 15px);
  clip-path: polygon(25px -1px, 0 52%, 25px 15px);
}

.button .icon .cannon:after {
  width: 6px;
  position: absolute;
  right: -3px;
  top: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.5px var(--cannon-light);
  background: linear-gradient(90deg, var(--cannon-dark), var(--cannon-light));
}
</style> 