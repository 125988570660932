<template>
  <section id="about" class="relative py-24 bg-zinc-100 overflow-hidden">
    <!-- Background Grid Overlay -->
    <div class="absolute inset-0 pointer-events-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNDB2NDBoLTQweiIvPjxwYXRoIGQ9Ik00MCAyMGgtNDBNMjAgNDB2LTQwIiBzdHJva2U9InJnYmEoMCwwLDAsMC4xKSIgc3Ryb2tlLXdpZHRoPSIuNSIvPjwvZz48L3N2Zz4=')] opacity-40" />

    <!-- Background effects -->
    <div class="absolute inset-0 overflow-hidden">
      <div class="absolute top-1/4 left-1/4 w-96 h-96 bg-amber-500/5 rounded-full filter blur-3xl" />
      <div class="absolute bottom-1/4 right-1/4 w-96 h-96 bg-amber-600/5 rounded-full filter blur-3xl" />
    </div>
    
    <div class="relative z-10 mx-auto max-w-7xl px-4">
      <div class="text-center">
        <h2 class="text-4xl sm:text-5xl md:text-7xl font-black uppercase bg-gradient-to-r from-amber-400 via-amber-500 to-amber-400 bg-clip-text text-transparent tracking-tight max-w-full overflow-hidden px-2">
          {{ translations[lang].about.title }}
        </h2>
        <p class="mt-4 text-lg text-zinc-600 max-w-3xl mx-auto">
          {{ translations[lang].about.description }}
        </p>
      </div>

      <!-- Timeline -->
      <div class="mt-20 relative">
        <!-- Timeline line -->
        <div class="absolute left-[20px] md:left-1/2 top-0 h-full w-1 bg-amber-300 md:-translate-x-1/2"></div>
        
        <!-- Timeline items -->
        <div class="relative">
          <div 
            v-for="(item, index) in translations[lang].about.timeline" 
            :key="index"
            class="mb-16 md:mb-24 timeline-item flex flex-col md:flex-row"
            :class="index % 2 === 0 ? 'md:flex-row-reverse' : ''"
          >
            <!-- Year bubble -->
            <div class="absolute left-0 md:left-1/2 translate-x-[15px] md:-translate-x-1/2 z-20">
              <div class="timeline-bubble w-10 h-10 rounded-full flex items-center justify-center text-white shadow-xl">
                <span class="text-base font-bold">{{ item.year }}</span>
              </div>
            </div>
            
            <!-- Content card -->
            <div class="timeline-content ml-16 md:ml-0 md:w-[45%] bg-white rounded-lg shadow-lg p-6 relative">
              <!-- Title -->
              <h3 class="text-xl font-bold text-zinc-900 mb-2">
                {{ item.title }}
              </h3>
              
              <!-- Description -->
              <p class="text-zinc-600">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { translations } from '@/i18n/translations'
import { onMounted } from 'vue'

interface Props {
  lang: 'en' | 'lv' | 'ru'
}

defineProps<Props>()

onMounted(() => {
  // Add animation on scroll effects with regular JS instead of Vue Motion
  const animateTimeline = () => {
    const timelineItems = document.querySelectorAll('.timeline-item')
    const timelineBubbles = document.querySelectorAll('.timeline-bubble')
    const timelineContent = document.querySelectorAll('.timeline-content')
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate')
        }
      })
    }, { threshold: 0.1 })
    
    timelineItems.forEach(item => observer.observe(item))
    timelineBubbles.forEach(bubble => observer.observe(bubble))
    timelineContent.forEach(content => observer.observe(content))
  }
  
  animateTimeline()
})
</script>

<style scoped>
.timeline-bubble {
  background: linear-gradient(135deg, #f59e0b, #d97706);
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease;
}

.timeline-content {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

/* Animation classes that will be added on scroll */
.timeline-bubble.animate {
  opacity: 1;
  transform: scale(1);
}

.timeline-content.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Timeline animations for alternate sides */
.timeline-item {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.timeline-item.animate {
  opacity: 1;
}

@media (min-width: 768px) {
  .timeline-item:nth-child(odd) .timeline-content {
    transform: translateX(-20px);
  }
  
  .timeline-item:nth-child(even) .timeline-content {
    transform: translateX(20px);
  }
  
  .timeline-item.animate:nth-child(odd) .timeline-content,
  .timeline-item.animate:nth-child(even) .timeline-content {
    transform: translateX(0);
  }
}
</style> 